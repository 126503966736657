import cmsCategoryObject from '@/entities/cmsCategory/interface'
import cmsPageObject from '@/entities/cmsPage/interface'

export const buildMenuCols = (categories: cmsCategoryObject[], numberOfCols: number = 4) => {
  const menuCols: any[] = []

  // filter out pages with page.onlineStatus === 'ONLINE'
  categories = categories.map((category) => {
    return {
      ...category,
      pages: category.pages.filter((page) => page.onlineStatus === 'ONLINE'),
    }
  })

  // count pages
  let totalPagesCount = 0
  categories.map((category) => {
    totalPagesCount += category.pages.length
  })

  // spread number of pages evenly over cols
  const colMaxPages = totalPagesCount / numberOfCols
  let currentColMenus: any[] = []
  let currentColIndex = 0
  let currentColPagesCount = 0
  categories.forEach((category) => {
    if (currentColPagesCount > 0 && currentColPagesCount + category.pages.length > colMaxPages && currentColIndex + 1 < numberOfCols) {
      menuCols.push(currentColMenus)
      currentColMenus = []
      currentColIndex += 1
      currentColPagesCount = 0
    }
    currentColMenus.push({
      title: category.name,
      items: buildMenuItems(category.seoSettings.urlSlug, category.pages),
    })
    currentColPagesCount += category.pages.length
  })
  menuCols.push(currentColMenus)

  return menuCols
}

const buildMenuItems = (categoryUrlSlug: string, pages: cmsPageObject[]) => {
  return pages.map((page) => {
    return {
      href: `/${categoryUrlSlug}/${page.seoSettings.urlSlug}`,
      label: page.name,
    }
  })
}
